import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-master/master/medical-facility/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useMedicalFacilityFormStore = defineStore({
  id: "medicalFacilityFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name || "",
          address: this.formData.address || "",
          email: this.formData.email || "",
          password: this.formData.password || "",
          company_id: this.formData.company_id || "",
          license_start_date: this.formData.license_start_date || "",
          license_end_date: this.formData.license_start_date || "",
          license_number: this.formData.license_number || "",
          license_file: this.formData.license_file || "",
          pic_name: this.formData.pic_name || "",
          siup: this.formData.siup || "",
          sk: this.formData.sk || "",
          city_id: this.formData.city_id,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_signature_file: this.formData.pic_signature_file,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key]) delete payload[key];
        });
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          name: this.formData.name || "",
          address: this.formData.address || "",
          email: this.formData.email || "",
          company_id: this.formData.company_id || "",
          license_start_date: this.formData.license_start_date || "",
          license_end_date: this.formData.license_start_date || "",
          license_number: this.formData.license_number || "",
          license_file: this.formData.license_file || "",
          pic_name: this.formData.pic_name || "",
          siup: this.formData.siup || "",
          sk: this.formData.sk || "",
          city_id: this.formData.city_id,
          pic_id_number: this.formData.pic_id_number,
          pic_email: this.formData.pic_email,
          pic_phone: this.formData.pic_phone,
          pic_signature_file: this.formData.pic_signature_file,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key]) delete payload[key];
        });
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async switchStatus(status: boolean) {
      this.stateIsLoading = true;
      try {
        const payload = {
          is_active: status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical/facilities/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = {
        name: data.name || "",
        address: data.address || "",
        email: data.email || "",
        password: data.password || "",
        company_id: data.company_id || "",
        license_start_date: data.license_start_date || "",
        license_end_date: data.license_start_date || "",
        license_number: data.license_number || "",
        license_file: data.license_file || "",
        pic_name: data.pic_name || "",
        siup: data.siup || "",
        sk: data.sk || "",
        city_id: data.city_id,
        province_id: data.province_id,
        pic_id_number: data.pic_id_number,
        pic_email: data.pic_email,
        pic_phone: data.pic_phone,
        pic_signature_file: data.pic_signature_file,
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
