import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6eb39fb9")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onClick"]
_popScopeId()


import { useMedicalFacilityFormStore } from "@/store/pinia/gws-master/master-data/medical-facility/useMedicalFacilityFormStore";
import { useMedicalFacilityListStore } from "@/store/pinia/gws-master/master-data/medical-facility/useMedicalFacilityListStore";
import { MedicalItem } from "@/core/types/gws-master/master/medical-facility/ListItem";
import { simpleFormatDate } from "@/core/helpers/date-format";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["delete-data", "edit-data", "toggle-status"],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useMedicalFacilityListStore();
const formStore = useMedicalFacilityFormStore();

const handleEditData = (item: MedicalItem) => {
  const editRow = {
    name: item.name || "",
    address: item.address || "",
    email: item.user.email || "",
    password: "",
    company_id: item.company.id || "",
    license_start_date: item.license_start_date || "",
    license_end_date: item.license_start_date || "",
    license_number: item.license_number || "",
    license_file: item.license_file || "",
    pic_name: item.pic_name || "",
    province_id: item.province.id || "",
    siup: item.siup || "",
    sk: item.sk || "",
    city_id: item.city.id,
    pic_id_number: item.pic_id_number,
    pic_email: item.pic_email,
    pic_phone: item.pic_phone,
  };
  formStore.setFormData(editRow);
  emits("edit-data", item);
};
const toggleStatus = (event) => {
  emits("toggle-status", event);
};
const handleDeleteData = (event) => {
  emits("delete-data", event);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            type: "index",
            label: "No.",
            "min-width": "20"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama",
            "min-width": "100",
            align: "left"
          }),
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID Faskes",
            "min-width": "100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "siup",
            label: "Nomor SIUP",
            "min-width": "100"
          }),
          _createVNode(_component_el_table_column, {
            prop: "license_end_date",
            label: "Tanggal Berakhir Izin",
            "min-width": "100"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDate)(scope.row.license_end_date)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "status",
            label: "Status"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_switch, {
                modelValue: scope.row.is_active,
                "onUpdate:modelValue": ($event: any) => (scope.row.is_active = $event),
                class: "ml-2",
                style: {"--el-switch-on-color":"#13ce66","--el-switch-off-color":"#ff4949"},
                onChange: ($event: any) => (toggleStatus(scope.row))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                (_unref(checkButtonPermission)('U'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-edit",
                      id: scope.id
                    }, [
                      _createVNode(_component_inline_svg, {
                        src: `media/gws/pencil-alt.svg`,
                        onClick: ($event: any) => (handleEditData(scope.row))
                      }, null, 8, ["src", "onClick"])
                    ], 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_unref(checkButtonPermission)('D'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn-delete",
                      onClick: ($event: any) => (handleDeleteData(scope.row))
                    }, [
                      _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                    ], 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})